html, body {
  font-family: 'GTWalsheimPro', sans-serif;
}
main {
  overflow: hidden;
}
section {
  overflow: hidden;
}
.video_wrapper {
  position:relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  &::after {
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}
.container{
  padding: 0 15px;
  width: 100%;
  margin: 0 auto;
  max-width: 700px;
  @include res(desktop) {
    max-width: 850px;
  }
  @include res(xlarge) {
    max-width: 1034px;
  }
  @include res(xxlarge) {
    max-width: 1200px;
  }
}

.mw-100 {
  max-width: 100%;
}