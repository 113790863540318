.usually {
  padding-top: 86px;
  @include res(desktop) {
    padding-top: 70px;
  }
  &--title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: #1c252f;
    margin-top: 0;
    max-width: 578px;
    margin-left: auto;
    margin-right: auto;
    @include res(desktop) {
      font-size: 52px;
    }
    @include res(xlarge) {
      margin-bottom: 60px;
    }
  }
  &--asset {
    text-align: center;
    margin-bottom: 66px;
    img {
      max-width: 100%;
      @include res(desktop) {
        margin-left: -55px;
      }
    }
  }
}

.usually {
  &__list {
    max-width: 906px;
    margin: 0 auto;
  }
  &__item {
    position: relative;
    padding-left: 50px;
    margin-bottom: 40px;
    @include res(tablet) {
      display: flex;
    }
    @include res(desktop) {
      padding-left: 100px;
      margin-bottom: 6px;
    }
    &:before {
      content: "";
      width: 30px;
      height: 30px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='42.5' height='42.5' viewBox='0 0 42.5 42.5'%3E%3Cg id='Group_1142' data-name='Group 1142' transform='translate(-221 -8481)'%3E%3Cpath id='Path_738' data-name='Path 738' d='M21.25,0A21.25,21.25,0,1,1,0,21.25,21.25,21.25,0,0,1,21.25,0Z' transform='translate(221 8481)' fill='%2342d890'/%3E%3Cpath id='Path_737' data-name='Path 737' d='M7131.185,6470.746l4.91,4.91,8.489-8.49' transform='translate(-6895.634 2030.839)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='4'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-size: contain;
      position: absolute;
      top: 10px;
      left: 0;
      @include res(desktop) {
        width: 50px;
        height: 50px;
      }
    }
    &--text {
      @include res(tablet) {
        padding-right: 50px;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.75;
        text-align: left;
        color: #343434;
        margin-top: 0;
        @include res(desktop) {
          font-size: 20px;
        }
      }
    }
    &--price {
      width: 100%;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.75;
      text-align: left;
      white-space: nowrap;
      text-align: right;
      @include res(tablet) {
        width: 130px;
        text-align: left;
      }
    }
  }
}


.total {
  overflow: visible;
  position: relative;
  
  &--top {
    position: relative;
    padding-top: 94px;
    padding-bottom: 76px;
    @include res(xlarge) {
      padding-top: 30px;
    }
  }
  &--inner {
    text-align: center;
  }
  &--title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: #1c252f;
    margin-top: 0;
    margin-bottom: 30px;
    @include res(desktop) {
      font-size: 52px;
    }
    .styled {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        inset: -25% -7% -34% -8%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='217.828' height='88.389' viewBox='0 0 217.828 88.389'%3E%3Cpath id='Path_729' data-name='Path 729' d='M5939.221,12070.584s-192.375,11.792-115.007,63,245.764-2.062,180.874-35.635-85.518-20.075-85.518-20.075' transform='translate(-5803.847 -12068.466)' fill='none' stroke='%2300baff' stroke-linecap='round' stroke-width='4'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  &--afterTitle {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: #1c252f;
    margin-top: 0;
    margin-bottom: 10px;
    @include res(desktop) {
      font-size: 30px;
    }
    img {
      margin-left: 10px;
    }
    .styled {
      position: relative;
      white-space: nowrap;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 12px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='407.044' height='9.136' viewBox='0 0 407.044 9.136'%3E%3Cpath id='Path_730' data-name='Path 730' d='M5847.236,12222.245l407-6.136h-372.07' transform='translate(-5847.213 -12214.609)' fill='none' stroke='%23c7c7c7' stroke-width='3'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: right center;
      }
      
    }
  }
  &--info {
    font-size: 21px;
    font-weight: 500;
    line-height: 1.33;
    text-align: center;
    color: #343434;
    margin: 0;
    margin-bottom: 30px;
    @include res(xlarge) {
      margin-bottom: 42px;
    }
  }
  &--text {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: #1c252f;
    margin-top: 0;
  }
  &--btn {
    display: inline-block;
    position: relative;
    width: 539px;
    max-width: 100%;
    font-size: 17px;
    letter-spacing: 0.1em;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: #fff;
    border-radius: 13px;
    background-color: #42d990;
    text-decoration: none;
    padding: 29px 36px;
    margin: 23px 0 42px;
    z-index: 3;
    @include res(desktop) {
      font-size: 20px;
    }
    @include res(desktop) {
      font-size: 25px;
      margin-bottom: 65px;
    }
    &:before {
      content: "";
      background-color: rgba(0,0,0,0.04);
      position: absolute;
      top: -13px;
      right: -21px;
      bottom: -13px;
      left: -21px;
      border-radius: 31px;
      z-index: -1;
      border: 15px solid #00000005;
    }
    &:after {
      content: "";
      width: 60px;
      height: 83px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='65.484' height='86.725' viewBox='0 0 65.484 86.725'%3E%3Cg id='Group_1157' data-name='Group 1157' transform='translate(-1061.522 -15753.838)'%3E%3Cpath id='Path_733' data-name='Path 733' d='M3128.072,15564.438c25.692,25.959,4.3,54.977-39.391,67.866' transform='translate(-2014.154 190.808)' fill='none' stroke='%230a0a0a' stroke-width='4'/%3E%3Cpath id='Path_734' data-name='Path 734' d='M3050.907,15788.991s-9.78,26.127,1.6,19.889,22.229-13.7,22.229-13.7' transform='matrix(0.914, 0.407, -0.407, 0.914, 4711.026, 144.66)' fill='none' stroke='%230a0a0a' stroke-linejoin='round' stroke-width='4'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      left: 100%;
      top: -40px;
      margin-left: 50px;
    }
  }
  &--afterBtn {
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.11;
      text-align: center;
      color: #1c252f;
      @include res(desktop) {
        font-size: 18px;
      }
    }
  }

  &--bottom {
    background-color: #f3f3f4;
    padding: 30px 0;
    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.82;
      letter-spacing: normal;
      text-align: center;
      color: #262626;
      margin: 0;
      @include res(desktop) {
        font-size: 22px;
      }
      img {
        margin-right: 15px;
      }
    }
  }
}