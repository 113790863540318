.prodInfo {
  @include res(xlarge) {
    padding: 40px 0;
  }
  .container {
    @include res(xlarge) {
      max-width: 987px;
    }
  }
  &--content {
    position: relative;
    padding: 50px 0;
    max-width: 500px;
    margin: 0 auto;
    @include res(xlarge) {
      padding: 80px 0 64px;
      max-width: 452px;
      margin: 0;
    }
  }
  &--preTitle {
    font-family: 'Nanum Pen Script', cursive;
    font-size: 24px;
    line-height: 1.52;
    text-align: left;
    color: #42d990;
    margin-top: 0;
    @include res(desktop) {
      font-size: 29px;
      margin-bottom: 8px;
    }
  }
  &--title {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.5;
    text-align: left;
    color: #131548;
    margin-top: 0;
    @include res(desktop) {
      font-size: 36px;
      margin-bottom: 17px;
    }
  }
  &--text {
    p {
      font-size: 18px;
      line-height: 1.39;
      text-align: left;
      color: #131548;
      margin-top: 0;
      
    }
  }
  &--asset {
    margin-bottom: 20px;
   @include res(xlarge) {
    position: absolute;
    transform: translateY(-50%);
   }
   img {
    max-width: 100%;
    @include res(xlarge) {
      max-width: inherit;
    }
   }
  }
  &--content:nth-child(2n + 1) {
    .prodInfo--asset {
      left: 100%;
     
      top: 50%;
      @include res(xlarge) {
        margin-left: 40px;
      }
    }
  }
  &--content:nth-child(2n + 2) {
    margin-left: auto;
    @include res(desktop) {
      transform: translateX(69px);
    }
    .prodInfo--asset {
      right: 100%;
      top: 50%;
      @include res(xlarge) {
        margin-right: 40px;
      }
    }
  }
}