.music {
  background-color: #F7F7F8;
  padding-top: 60px;
  @include res(desktop) {
    padding-top: 90px;
  }
  &--scroll {
    position: relative;
    max-width: 406px;
    @include res(xlarge) {
      transform: translateX(-35px);
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 130px;
      z-index: 5;
      background-image: linear-gradient(to bottom, rgba(247, 247, 248, 0), #f7f7f8);
      pointer-events: none;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 130px;
      z-index: 5;
      background-image: linear-gradient(to bottom, #f7f7f8, rgba(247, 247, 248, 0));
      pointer-events: none;
    }
    .js-audioScroll {
      height: 542px;
      overflow: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    } 
  }
  &--title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.32;
    text-align: center;
    color: #1b252f;
    max-width: 600px;
    margin: 0 auto 45px;
    @include res(desktop) {
      font-size: 50px;
    }
  }
  &--text {
    font-size:18px;
    font-weight: 500;
    line-height: 2;
    color: #1b252f;
    max-width: 480px;
    @include res(desktop) {
      font-size: 20px;
      margin-left: 88px;
    }
    @include res(xlarge) {
      margin-left: 47px;
    }
  }
  &--inner {
    @include res(tablet) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}




.audioScroll {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  @include res(desktop) {
    width: 100%;
  }
  &__item {
    padding-bottom: 22px;
    width: calc( 50% - 7px);
    
    &:nth-child(2n + 2) {
      transform: translateY(120px);
    }
    &--inner {
      position:relative;
      border-radius: 30px;
      overflow: hidden;
      height: 225px;
    }
    &--asset {
      position: relative;
      height: 100%;
      @include res(desktop) {
        width: 100%;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: rgba(0,0,0,0.35);
        transition: all 0.4s ease;
      }
      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        transition: all 0.4s ease;
      }
    }
    &--text {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      z-index: 4;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
    }
    &--name {
      font-size: 15px;
      font-weight: 400;
      line-height: 2.2;
      letter-spacing: 0.02em;
      text-align: left;
      color: #fff;
      margin: 0;
    }
    &--new {
      font-size: 10px;
      font-weight: bold;
      line-height: 1;
      color: #4d4217;
      border-radius: 50px;
      padding: 4px 12px;
      background-color: #feff00;
      margin: 0;
    }
    &--btn {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
      transform: translate(-50%, -50%);
      border: 1px solid #fff;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
      padding: 5px 25px 5px 30px;
      color: #fff;
      text-decoration: none;
      letter-spacing: 0.02em;
      background-color: transparent;
      opacity: 1;
      transition: opacity 0.4s ease;
      @include res(xlarge) {
        opacity: 0;
        font-size: 18px;
        border: 2px solid #fff;
      }
      &:hover {
        cursor: pointer;
        & + .audioScroll__item--asset {
          img {
            transform: scale(1.2);
          }
        }
      }
      &:after {
        content: "play";
      }
      &.playing {
        opacity: 1;
        &:after {
          content: "pause";
        }
        & + .audioScroll__item--asset {
          &:after {
            background: rgba(0,0,0,0.75);
          }
          img {
            transform: scale(1.2);
          }
        }
      }
    }
    .music {
      position: absolute;
      left: -10000px;
      opacity: 0;
    }
    &:hover {
      .audioScroll__item--btn {
        opacity: 1;
        
      }
      .audioScroll__item--asset {
        &:after {
          background: rgba(0,0,0,0.75);
        }
      }
    }
  }
}

