.footer {
  background-color: #0d0d0d;
  padding-top: 126px;
  padding-bottom: 30px;
  overflow: visible;
  position: relative;
  &--inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &--logo {
    margin-bottom: 35px;
  }
  &--nav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    @include res(tablet) {
      display: flex;
      justify-content: center;
    }
    li {
      margin: 0 15px;
      a {
        font-size: 17px;
        font-weight: 500;
        line-height: 1.59;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}