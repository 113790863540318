.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  &--logo {
    display: inline-block;
    margin-bottom: 20px;
    img {
      width: 112px;
      @include res(desktop) {
        width: 150px;
      }
    }
  }
  &--inner {
    text-align: center;
    padding: 24px 0 8px;
    @include res(tablet) {
      text-align: left;
      display: flex;
      align-items: center;
      padding: 23px 0;
    }
    @include res(desktop) {
      padding: 20px 0;
    }
  }
}

.header__progress {
  background-color: #ebebeb;
  border-radius: 20px;
  overflow: hidden;
  height: 16px;
  width: 293px;
  margin: 0 auto;
  @include res(tablet) {
    height: 19px;
    margin-left: auto;
    margin-right: 0;
  }
  @include res(desktop) {
    width: 331px;
    height: 24px;
  }
  &--inner {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 1em;
    color: #fff;
    background-color: #00baff;
    height: 100%;
    width: 61%;
    border-radius: 20px;
    @include res(tablet) {
      font-size: 11px;
    }
    @include res(desktop) {
      font-size: 12px;
    }
  }
}