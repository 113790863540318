.licensedAudio {
  padding: 60px 0;
  background-color: #F7F7F8;
  @include res(desktop) {
    padding: 80px 0;
  }
  @include res(xlarge) {
    padding-top: 110px;
  }
  .container {
    @include res(xlarge) {
      max-width: 1291px;
    }
  }
 
  &--inner {
    background-image: linear-gradient(300deg, #ffa850 , #D91A4E);
    padding: 50px 30px;
    border-radius: 27px;
    @include res(desktop) {
      padding: 80px 30px 43px 30px;
    }
  }
  &--title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: #fff;
    margin-top: 0;
    @include res(desktop) {
      font-size: 46px;
      margin-bottom: 55px;
    }
  }
  &--asset {
    text-align: center;
    margin-bottom: 65px;
    @include res(desktop) {
      margin-bottom: 61px;
    }
    img {
      width: 400px;
      max-width: 100%;
      @include res(desktop) {
        width: 639px;
      }
    }
  }
  &--text {
    max-width: 683px;
    margin: 0 auto;
    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 2;
      text-align: center;
      color: #fff;
      margin-top: 0;
      @include res(desktop) {
        font-size: 20px;
        margin-bottom: 39px;
      }
    }
  }
}