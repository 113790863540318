@font-face {
  font-family: "GTWalsheimPro";
  src: url('../../fonts/Gt_WalsheimPro_Woff2/GTWalsheimPro-Light.woff2') format('woff2'),
       url('../../fonts/Gt_WalsheimPro_Woff/GTWalsheimPro-Light.woff') format('woff'),
       url('../../fonts/Gt_WalsheimPro_TTF/GTWalsheimPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "GTWalsheimPro";
  src: url('../../fonts/Gt_WalsheimPro_Woff2/GTWalsheimPro-Regular.woff2') format('woff2'),
       url('../../fonts/Gt_WalsheimPro_Woff/GTWalsheimPro-Regular.woff') format('woff'),
       url('../../fonts/Gt_WalsheimPro_TTF/GTWalsheimPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "GTWalsheimPro";
  src: url('../../fonts/Gt_WalsheimPro_Woff2/GTWalsheimPro-Medium.woff2') format('woff2'),
       url('../../fonts/Gt_WalsheimPro_Woff/GTWalsheimPro-Medium.woff') format('woff'),
       url('../../fonts/Gt_WalsheimPro_TTF/GTWalsheimPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "GTWalsheimPro";
  src: url('../../fonts/Gt_WalsheimPro_Woff2/GTWalsheimPro-Bold.woff2') format('woff2'),
       url('../../fonts/Gt_WalsheimPro_Woff/GTWalsheimPro-Bold.woff') format('woff'),
       url('../../fonts/Gt_WalsheimPro_TTF/GTWalsheimPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "GTWalsheimPro";
  src: url('../../fonts/Gt_WalsheimPro_Woff2/GTWalsheimPro-UltraBold.woff2') format('woff2'),
       url('../../fonts/Gt_WalsheimPro_Woff/GTWalsheimPro-UltraBold.woff') format('woff'),
       url('../../fonts/Gt_WalsheimPro_TTF/GTWalsheimPro-UltraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "GTWalsheimPro";
  src: url('../../fonts/Gt_WalsheimPro_Woff2/GTWalsheimPro-Black.woff2') format('woff2'),
       url('../../fonts/Gt_WalsheimPro_Woff/GTWalsheimPro-Black.woff') format('woff'),
       url('../../fonts/Gt_WalsheimPro_TTF/GTWalsheimPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}