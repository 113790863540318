.meetTwinkle {
  background-color: #131548;
  background-image: url('../img/twinkle-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding-top: 100px;
  position: relative;
  &--inner {
    text-align: center;
  }
  &--title {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.33;
    color: #fff;
    margin-top: 0;
    margin-bottom: 22px;
    @include res(desktop) {
      font-size: 52px;
    }
  }
  &--afterTitle {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.65;
    color: #fff;
    max-width: 609px;
    margin: 0 auto 22px;
    @include res(desktop) {
      font-size: 20px;
    }
  }
  &--btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 257px;
    min-height: 62px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    border-radius: 11px;
    background-color: #42d990;
    text-decoration: none;
    padding: 15px 30px;
    text-align: center;
    margin-bottom: 16px;
  }
  &--oldPrice {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    text-decoration: line-through;
    color: #fff;
    opacity: 0.29;
    margin-top: 0;
    margin-bottom: 3px;
    @include res(desktop) {
      font-size: 20px;
    }
  }
  &--newPrice {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    color: #fff;
    margin-top: 0;
    margin-bottom: 44px;
    @include res(desktop) {
      font-size: 20px;
    }
  }
  &--asset {
    img {
      max-width: 100%;
    }
  }
}

.customTracks {
  padding-top: 80px;
  @include res(desktop) {
    padding-top: 167px;
  }
  .container {
    @include res(xlarge) {
      max-width: 1203px;
    }
  }
  &--title {
    max-width: 684px;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: #fff;
    margin: 0 auto;
    margin-bottom: 20px;
    @include res(desktop) {
      font-size: 52px;
    }
  }
  &--content {
    // position: relative;
    padding: 30px 0 70px;
    @include res(desktop) {
      padding: 80px 0 140px;
    }
  }
}






.videoScroll {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__item {
    padding-bottom: 22px;
    &:nth-child(2n + 2) {
      transform: translateY(120px);
    }
    &--inner {
      position:relative;
      border-radius: 30px;
      overflow: hidden;
    }
    &--asset {
      position: relative;
      width: 186px;
      height: 225px;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: rgba(0,0,0,0.35);
        transition: all 0.4s ease;
      }
      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        transition: all 0.4s ease;
      }
    }
    &--text {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      z-index: 4;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
    }
    &--name {
      font-size: 15px;
      font-weight: 400;
      line-height: 2.2;
      letter-spacing: 0.02em;
      text-align: left;
      color: #fff;
      margin: 0;
    }
    &--new {
      font-size: 10px;
      font-weight: bold;
      line-height: 1;
      color: #4d4217;
      border-radius: 50px;
      padding: 4px 12px;
      background-color: #feff00;
      margin: 0;
    }
    &--btn {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
      transform: translate(-50%, -50%);
      border: 2px solid #fff;
      border-radius: 8px;
      font-size: 18px;
      font-weight: 500;
      padding: 5px 25px 5px 30px;
      color: #fff;
      text-decoration: none;
      letter-spacing: 0.02em;
      background-color: transparent;
      opacity: 1;
      transition: opacity 0.4s ease;
      @include res(xlarge) {
        opacity: 0;
      }
      &:hover {
        cursor: pointer;
        & + .videoScroll__item--asset {
          img {
            transform: scale(1.2);
          }
        }
      }
      &:after {
        content: "play";
      }
      &.playing {
        opacity: 1;
        &:after {
          content: "pause";
        }
        & + .videoScroll__item--asset {
          &:after {
            background: rgba(0,0,0,0.75);
          }
          img {
            transform: scale(1.2);
          }
        }
      }
    }
    .music {
      position: absolute;
      left: -10000px;
      opacity: 0;
    }
    &:hover {
      .videoScroll__item--btn {
        opacity: 1;
        
      }
      .videoScroll__item--asset {
        &:after {
          background: rgba(0,0,0,0.75);
        }
      }
    }
  }
}



.customTracks__scroll {
  max-width: 710px;
  margin: 0 auto;
  position: relative;
  @include res(xlarge) {
    margin: 0;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 4;
    width: 100%;
    height: 97px;
    background-image: linear-gradient(to bottom, rgba(19, 21, 72, 0), #131548);
    
    pointer-events: none;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    width: 100%;
    height: 97px;
    background-image: linear-gradient(to top, rgba(19, 21, 72, 0), #131548);
    pointer-events: none;
  }
  .js-videoScroll {
    height: 342px;
    overflow: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  } 
  .videoScroll {
    &__item {
      width: calc( 50% - 7.5px);
      transform: none;
      @include res(tablet) {
        width: calc( 33.33% - 7.5px);
      }
      @include res(desktop) {
        width: calc( 25% - 7.5px);
      }
    }
  }
}




.audioHand {
  position: absolute;
  left: -100000px;
  width: 627px;
  max-width: 627px;
  z-index: 5;
  height: 0;
  overflow: hidden;
  @include res(xlarge) {
    height: inherit;
    left: inherit;
    bottom: -240px;
    right: -122px;
  }
  @include res(laptop) {
    right: -50px;
  }
  img {
    width: 672px;
  }
  &--inner {
    position: absolute;
    top: 82px;
    left: 13px;
    height: 197px;
    width: 422px;
    border-radius: 22px;
    overflow: hidden;
  }
  &--name {
    position: absolute;
    bottom: 3px;
    left: 50px;
    font-size: 13px;
    font-weight: 500;
    z-index: 5;
    color: #fff;
  }
}



.audioplayer{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &:after{
    content: "";
    background-image: url(https://twinkle.io/wp-content/uploads/2021/04/FADED_PHONE_OVERLAY@2x.png);
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 78px;
    z-index: 1;
  }
  &-playpause{
    background-image: url(https://twinkle.io/wp-content/uploads/2021/04/PLAY_ICON.png);	
    width: 16px;
    height: 20px;
    position: absolute;
    bottom: 23px;
    left: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 5;
    &:hover{
      cursor: pointer;
    }
  }
  &-playing .audioplayer-playpause{
    background-image: url(https://twinkle.io/wp-content/uploads/2021/04/pause-mobile.png);
  }
  &-bar{
    position: absolute;
    bottom: 20px;
    left: 50px;
    right: 22px;
    bottom: 29px;
    height: 7px;
    border-radius: 10px;
    overflow: hidden;
    background-color: rgba(255,255,255, 0.13);
    z-index: 5;
  }
  &-bar-played{
    background-color: #8FDE6C;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  &-time{
    display: none;
  }
  &-volume{
    display: none;
  }
}