.tracksAdded {
  @include res(desktop) {
    padding-top: 13px;
  }
  .container {
    @include res(desktop) {
      max-width: 1245px;
    }
  }
  &--inner {
    background-image: linear-gradient(122deg, #00baff 11%, #0056f8 99%);
    border-radius: 26px;
    padding: 50px 30px 70px;
    @include res(desktop) {
      padding: 40px 30px 70px;
    }
    @include res(xlarge) {
      padding: 102px 116px 129px;
    }
  }
  &--title {
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin-top: 0;
    max-width: 687px;
    margin-left: auto;
    margin-right: auto;
    @include res(desktop) {
      font-size: 45px;
    }
  }
  &--asset {
    margin-bottom: 20px;
    img {
      max-width: 100%;
    }
  }
  &--text {
    max-width: 492px;
    margin: 0 auto;
    @include res(xlarge) {
      margin-right: 0;
    }
    p {
      font-size: 18px;
      line-height: 2;
      text-align: left;
      color: #fff;
      margin: 0;
      @include res(desktop) {
        font-size: 20px;
      }
    }
  }
  &--content {
    @include res(tablet) {
      text-align: center;
    }
    @include res(xlarge) {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
}