.unlicensedAudio {
  padding: 60px 0 40px;
  background-color: #f7f7f8;
  @include res(desktop) {
    padding-top: 5px;
  }
  @include res(xlarge) {
    padding-bottom: 120px;
  }
  .container {
    max-width: 876px;
  }
  &--title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: #1b252f;
    margin-top: 0;
    max-width: 646px;
    margin-left: auto;
    margin-top: auto;
    @include res(desktop) {
      font-size: 52px;
    }
  }
  &--text {
    @include res(xlarge) {
      margin-top: -37px;
    }
    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 2;
      text-align: left;
      color: #1b252f;
    }
  }
  &--content {
    position: relative;
    max-width: 416px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 0;
    @include res(desktop) {
      margin-right: inherit;
      padding: 100px 0;
    }
  }
  &--asset {
    @include res(desktop) {
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
    img {
      max-width: 100%;
      @include res(desktop) {
        max-width: inherit;
      }
    }
  }
}