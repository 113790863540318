.hero {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0f1240;
    z-index: -1;
  }
  &:after {
    content: "";
    position: absolute;
    top: 30%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-image: radial-gradient(circle at 50% 50%, #236f8b, #0f1240 72%);
  }
}
 

.hero__top {
  padding-top: 120px;
  @include res(desktop) {
    padding: 99px 0 10px;
  }
  &--preTitle {
    font-family: 'Nanum Pen Script', cursive;
    font-size: 26px;
    line-height: 1.34;
    text-align: center;
    color: #5cffb0;
    margin-top: 0;
    margin-bottom: 4px;
    @include res(desktop) {
      font-size: 38px;
      margin-bottom: 2px;
    }
    span {
      display: inline-block;
      transform: rotate(-4deg);
    }
  }
  &--title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.09;
    text-align: center;
    color: #fff;
    margin-top: 0;
    max-width: 733px;
    margin: 0 auto;
    margin-bottom: 15px;
    @include res(desktop) {
      font-size: 57px;
    }
  }
  &--afterTitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    text-align: center;
    color: #fff;
    opacity: 0.69;
    margin-top: 0;
    margin-bottom: 37px;
    @include res(desktop) {
      font-size: 24px;
    }
  }
  &--list {
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    max-width: 400px;
    @include res(xlarge) {
      max-width: 100%;
      justify-content: center;
      margin-bottom: 24px;
    }
    li {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.75;
      color: #fff;
      padding-left: 40px;
      margin-bottom: 10px;
      @include res(desktop) {
        font-size: 20px;
        padding-left: 55px;
      }
      @include res( xlarge) {
        &:not(:first-child) {
          margin-left: 24px;
        }
      }
    
      &:before {
        content: "";
        width: 27px;
        height: 27px;
        background-image: url('../img/elements/hero--check.svg');
        background-size: contain;
        position: absolute;
        left: 0;
        top: 0;
        @include res(desktop) {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
  &--asset {
    max-width: 857px;
    margin: 0 auto;
    iframe {
      border-radius: 14px;
      overflow: hidden;
    }
  }
}


.hero__bottom {
  position: relative;
  padding: 55px 0 115px;
  &:before {
    content: "";
    width: 302px;
    height: 520px;
    position: absolute;
    left: 0;
    bottom: 43px;
    background-image: url('../img/3D-TWINKLE-GUY.png');
    background-size: contain;
    display: none;
    @include res(xlarge) {
      display: block;
    }
  }
  &:after {
    content: "";
    width: 353px;
    height: 491px;
    position: absolute;
    right: 0;
    bottom: -49px;
    background-image: url('../img/IMAC-Twinkle.png');
    background-size: contain;
    display: none;
    @include res(xlarge) {
      display: block;
    }
  }
  &--inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &--title {
    position: relative;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.35;
    text-align: center;
    color: #fff;
    margin-top: 0;
    margin-bottom: 7px;
    @include res(desktop) {
      font-size: 43px;
    }
    .text-absolute {
      font-family: 'Nanum Pen Script', cursive;
      position: absolute;   
      bottom: 79%;
      left: -10px;
      font-size: 22px;
      line-height: 1.34;
      color: #fff;
      white-space: nowrap;
      transform: rotate(-14deg);
      @include res(desktop) {
        font-size: 29px;
        left: inherit;
        right: 95%;
      }
      &:after {
        content: "";
        width: 31px;
        height: 25px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34.118' height='26.787' viewBox='0 0 34.118 26.787'%3E%3Cg id='Group_1147' data-name='Group 1147' transform='translate(-400.563 -945.509)'%3E%3Cpath id='Path_742' data-name='Path 742' d='M-188.416,949.388s29.7-15.23,27.482,12.32' transform='matrix(0.995, 0.105, -0.105, 0.995, 689.924, 26.329)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2'/%3E%3Cpath id='Path_743' data-name='Path 743' d='M-164.5,959.555s-1.5,10.894,1.76,8.751a33.279,33.279,0,0,0,5.492-4.54' transform='matrix(0.995, 0.105, -0.105, 0.995, 689.845, 24.309)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-position: center;
        background-size: contain;
        position: absolute;
        left: 100%;
        top: 20%;
        margin-left: 10px;
      }
    }
  }
  &--afterTitle {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.35;
    text-align: center;
    color: #fff;
    margin-top: 0;
    margin-bottom: 25px;
    @include res(desktop) {
      font-size: 26px;
    }
    .cross {
      opacity: 0.38;
      text-decoration: line-through;
    }
    .styled {
      position: relative;
      color: #f7ff2e;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: -10px;
        right: 0;
        height: 5px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='120.198' height='5.052' viewBox='0 0 120.198 5.052'%3E%3Cpath id='Path_724' data-name='Path 724' d='M5813.7,1239.213l-85.212-3.052H5707.33v-2h120.2v2h-43.157l29.4,1.053Z' transform='translate(-5707.33 -1234.161)' fill='%23fff'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
      }
    }
  }
  &--btn {
    display: inline-block;
    position: relative;
    width: 532px;
    max-width: 100%;
    font-size: 17px;
    letter-spacing: 0.1em;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    border-radius: 13px;
    background-color: #42d990;
    text-decoration: none;
    padding: 24px 26px;
    margin: 23px 0 42px;
    z-index: 3;
    text-align: center;
    @include res(desktop) {
      font-size: 27px;
      padding: 34px 36px;
    }
    &:before {
      content: "";
      background-color: #ffffff0a;
      position: absolute;
      top: -13px;
      right: -21px;
      bottom: -13px;
      left: -21px;
      border-radius: 31px;
      z-index: -1;
      border: 15px solid #ffffff05;
    }
  }
  &--afterBtn {
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.11;
      text-align: center;
      color: #fff;
      margin-top: 0;
      @include res(desktop) {
        font-size: 18px;
      }
    }
  }
}


.hero__noThanks {
  background-color: #675f7e;
  padding: 43px 0 34px;
  .container {
    max-width: 870px;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.82;
    text-align: center;
    color: #fff;
    margin: 0;
    @include res(desktop) {
      font-size: 22px;
    }
    img {
      margin-right: 7px;
    }
  }
}